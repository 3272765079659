import React from 'react'
import {
  QRImage,
  QrContent,
  QrCard,
  QRCodeStyle,
  TextBoxStyle,
  TextOrderNumber,
  TextTitle,
  CISSContentWrapper,
  CISSContent,
  Link,
  IconWrapper
} from './styles'
import { CommonProps } from 'src/utils/common-util'
import { ReturnData } from 'src/types/return'
import { PrinterSupportLink } from 'src/utils/constants'

import IconWarning from '@veneer/core/dist/esm/scripts/icons/icon_warning_alt'
interface QrCardComponentProps {
  commonProps: CommonProps
  returnDetails: ReturnData
  isCissPrinter: boolean
}
const QrCardComponent: React.FC<QrCardComponentProps> = ({
  commonProps,
  returnDetails,
  isCissPrinter
}) => {
  const { t } = commonProps
  return (
    <QrCard data-testid="qrcardVisible">
      <QRImage>
        <QRCodeStyle
          value={`FDX1RT${returnDetails?.returnNumber}`}
          viewBox={`0 0 256 256`}
        />
        <TextBoxStyle>
          <TextTitle>
            {t('dashboard.FedExCard.rmaNumber', 'RMA Number:')}
          </TextTitle>
          <TextOrderNumber>{`FDX1RT${returnDetails?.returnNumber}`}</TextOrderNumber>
        </TextBoxStyle>
      </QRImage>
      <QrContent>
        {t(
          'dashboard.FedExCard.content2',
          'If you still have your original packaging, please use it for your return; otherwise, FedEx will provide it at no charge.'
        )}
        {isCissPrinter && (
          <CISSContentWrapper>
            <IconWrapper>
              <IconWarning color={'#0073A8'} size={24} />
            </IconWrapper>
            <CISSContent>
              {t(
                'dashboard.FedExCard.content3',
                'To prevent ink leaks or printer damage, please visit'
              )}{' '}
              <Link
                rel="noreferrer"
                target="_blank"
                href="https://support.hp.com/us-en/document/ish_4512631-3975737-16?openCLC=true"
              >
                {PrinterSupportLink}
              </Link>
            </CISSContent>
          </CISSContentWrapper>
        )}
      </QrContent>
    </QrCard>
  )
}

export { QrCardComponent }
