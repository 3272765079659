import {
  CardError,
  LoadingHandler
} from '@monetization/hpaip-ui-shared-components'
import {
  BillingCyclePeriods,
  BillingCyclePeriodsClient,
  BillingCyclePeriodsResponse,
  SubscriptionStateEnum,
  useLazyFetchRequest,
  UsageData
} from '@monetization/hpaip-notification-rules-react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { StyledCard, StyledIconCalendarMonth } from './styles'
import { getCurrentPeriod, getErrorMessages, getTotalPages } from './utils'
import { SummaryProgress } from './components/SummaryProgress'
import MonthlyPlanPeriod from './components/MonthlyPlanPeriod'
import MonthlyPlanPages from './components/MonthlyPlanPages'
import MonthlyPlanHeader from './components/MonthlyPlanHeader'

export const MonthlyPlanSummaryCard: React.FunctionComponent<any> = ({
  printerData,
  refreshCallback,
  commonProps,
  returnStatus
}) => {
  const { authProvider, stack, t, bizlogicId } = commonProps

  const [currentPeriod, setCurrentPeriod] = useState<BillingCyclePeriods>()
  const [usageDataState, setUsageDataState] = useState<
    UsageData & LastUpdateAt
  >()

  const billingCyclePeriodsClient = useMemo(() => {
    return new BillingCyclePeriodsClient(authProvider, stack)
  }, [authProvider, stack])

  const fetchCallback = useCallback(async () => {
    if (currentPeriod?.billingCycleId) {
      const data =
        await billingCyclePeriodsClient.getBillingCycleUsageByBillingCycleId(
          bizlogicId,
          printerData?.root?.subscriptionId,
          printerData?.printer?.entityId,
          currentPeriod?.billingCycleId
        )
      setUsageDataState(data)
      return data
    }
  }, [
    billingCyclePeriodsClient,
    bizlogicId,
    printerData?.root?.subscriptionId,
    printerData?.printer?.entityId,
    currentPeriod?.billingCycleId
  ])
  // Function to trigger fetchCallback from the child
  const triggerFetchCallback = () => {
    fetchCallback()
    refreshCallback()
  }

  interface LastUpdateAt {
    lastUpdatedAt?: string
  }
  // prettier-ignore
  const [fetchUsageData, usageDataStateInital] = useLazyFetchRequest<UsageData & LastUpdateAt>(fetchCallback)
  const [fetchBillingCycles, billingCycles] = useLazyFetchRequest<
    BillingCyclePeriodsResponse[]
  >(() => {
    return billingCyclePeriodsClient.getBillingCyclesByEntityId(
      bizlogicId,
      printerData?.root?.subscriptionId,
      printerData?.instantInk?.entityId
    )
  })

  const {
    state: printerStatus,
    showErrorMessage,
    disableCard
  } = useMemo(() => {
    const disableCardState = ['suspended']
    // prettier-ignore
    const errorMessage = [
      SubscriptionStateEnum.PENDING,
      SubscriptionStateEnum.CANCELED
    ]
    if (!printerData?.printer)
      return { state: '', showErrorMessage: false, disableCard: true }
    const cancelledBillingPassed =
      printerData?.printer?.state === SubscriptionStateEnum.CANCELING &&
      returnStatus != null
    return {
      state: printerData?.printer.state,
      disableCard: disableCardState.includes(printerData?.printer.state),
      showErrorMessage:
        errorMessage.includes(printerData?.printer.state) ||
        cancelledBillingPassed
    }
  }, [printerData?.printer, returnStatus])
  useEffect(() => {
    if (
      printerData?.instantInk?.entityId &&
      printerData?.root?.subscriptionId &&
      !showErrorMessage &&
      !billingCycles?.called
    ) {
      fetchBillingCycles()
    }
  }, [
    printerData?.subscriptionId,
    printerData?.instantInk,
    showErrorMessage,
    fetchBillingCycles,
    billingCycles,
    printerData?.root?.subscriptionId
  ])

  useEffect(() => {
    const { loading, data } = billingCycles

    if (!loading && data?.length && !currentPeriod) {
      const period = getCurrentPeriod(data)
      if (period) {
        setCurrentPeriod(period)
      }
    }
  }, [currentPeriod, fetchUsageData, billingCycles])

  useEffect(() => {
    if (currentPeriod) {
      fetchUsageData()
    }
  }, [currentPeriod, fetchUsageData])

  const summaryData = useMemo(() => {
    const { loading, data } = usageDataStateInital
    const updatedData = usageDataState ? usageDataState : data

    if (!loading && updatedData) {
      const {
        printPlanPageAllowance,
        printPlanPageAllowancePrinted,
        printPlanRolloverPageAllowance,
        printPlanRolloverPageAllowancePrinted,
        printPlanRolloverPageAllowanceMax,
        printPlanOveragePagesPrinted,
        printPlanOveragePageBlocks,
        monthlyOveragePageBlockSize
      } = updatedData

      return {
        printPlanPageAllowance,
        printPlanPageAllowancePrinted,
        printPlanRolloverPageAllowance,
        printPlanRolloverPageAllowancePrinted,
        printPlanRolloverPageAllowanceMax,
        printPlanOveragePagesPrinted,
        printPlanOveragePageBlocks,
        monthlyOveragePageBlockSize
      }
    }
  }, [usageDataStateInital, usageDataState])

  const totalPages = useMemo(() => getTotalPages(summaryData), [summaryData])

  const { isLoading, isError } = useMemo(() => {
    return {
      isLoading:
        billingCycles.loading ||
        usageDataStateInital.loading ||
        (!billingCycles.called && !billingCycles.loading) ||
        (!usageDataStateInital.called && !usageDataStateInital.loading),
      isError:
        (billingCycles.error && !billingCycles.data) ||
        (usageDataStateInital.error && !usageDataStateInital.data) ||
        (!currentPeriod && !billingCycles.loading && billingCycles.called) ||
        showErrorMessage
    }
  }, [usageDataStateInital, currentPeriod, billingCycles, showErrorMessage])

  return (
    <StyledCard $disabled={disableCard}>
      <MonthlyPlanHeader
        lastUpdatedAt={usageDataState?.lastUpdatedAt}
        printerData={printerData}
        triggerFetchCallback={triggerFetchCallback}
        commonProps={commonProps}
      />
      <LoadingHandler
        loading={isLoading}
        error={Boolean(isError)}
        $position="absolute"
        loadingText={t('dashboard.paas.loading', 'Loading...')}
        customError={
          <CardError
            icon={<StyledIconCalendarMonth size={64} />}
            texts={getErrorMessages(
              printerStatus,
              t,
              printerData?.isCancellationTriggered
            )}
          />
        }
      >
        <MonthlyPlanPeriod
          startDate={
            currentPeriod?.customerStartDate
              ? currentPeriod?.customerStartDate
              : currentPeriod?.startDate
          }
          endDate={
            currentPeriod?.customerEndDate
              ? currentPeriod?.customerEndDate
              : currentPeriod?.endDate
          }
        />
        {summaryData && (
          <SummaryProgress
            planDetails={summaryData}
            commonProps={commonProps}
            printerData={printerData}
          />
        )}

        <MonthlyPlanPages totalPages={totalPages} commonProps={commonProps} />
      </LoadingHandler>
    </StyledCard>
  )
}
