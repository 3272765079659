import tokens from '@veneer/tokens'
import styled from 'styled-components'
import QRCode from 'react-qr-code'
const { size4, size6, smMax, size2, size3, size1, size8 } = tokens.layout
const { size1: fontSize1, lineHeight2, size9 } = tokens.typography
const { gray9, white, gray12, hpBlue7 } = tokens.color
export const QRImage = styled.div`
  padding: ${size6} ${size2} ${size3} ${size2};
  border-radius: 5.6px;
  background: ${white};
  display: flex;
  gap: ${size1};
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: ${smMax}) {
    border-radius: ${size2};
    gap: ${size1};
    display: flex;
    justify-content: center;
    padding: ${size8} ${size4} ${size4} ${size4};
  }
`
export const TextBoxStyle = styled.div`
  width: 155px;
  padding: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`
export const TextTitle = styled.div`
  overflow-wrap: anywhere;
  font-size: ${size4};
  font-weight: 500;
  line-height: ${size6};
  color: ${gray12};
  font-weight
`
export const TextOrderNumber = styled.div`
  overflow-wrap: anywhere;
  font-size: ${size4};
  font-weight: 400;
  line-height: ${size6};
  color: ${gray12};
  font-weight
`

export const QRCodeStyle = styled(QRCode)`
  width: 141px;
  height: 141px;
  @media screen and (max-width: ${smMax}) {
    width: 200px;
    height: 200px;
  }
`
export const QrContent = styled.div`
  font-size: ${fontSize1};
  line-height: ${lineHeight2};
  color: ${gray9};
  @media screen and (max-width: ${smMax}) {
    margin-top: ${size6};
    margin-bottom: ${size4};
  }
`

export const CISSContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${size4};
`
export const CISSContent = styled.div`
  font-size: ${fontSize1};
  line-height: ${lineHeight2};
  color: ${gray9};
  margin-left: ${size3};
  @media screen and (max-width: ${smMax}) {
    margin-bottom: ${size4};
  }
`
export const QrCard = styled.div`
  display: flex;
  gap: ${size9};
  align-items: center;
  width: 50%;
  @media screen and (max-width: ${smMax}) {
    display: block;
    width: auto;
  }
`
export const Link = styled.a`
  margin-top: ${size1};
  font-size: ${fontSize1};
  line-height: 18px;
  color: ${hpBlue7};
  word-break: break-all;
  text-decoration: underline;
`
export const IconWrapper = styled.div`
  display: inline-flex;
  width: auto;
  height: auto;
`
