const checkRemorsePendingTrailData = (pendingData: any) => {
  const getPendingCancellation = (pendingData: any) => {
    if (!pendingData) return null

    const filteredCancellations = pendingData.contents.filter(
      (content: any) =>
        content.type === 'cancellation' &&
        content.reason.type !== 'warrantyRepairReplacement'
    )

    return filteredCancellations[0] || null // Return the first element or null
  }

  const isCancelledInRemorse = (pendingData: any) => {
    if (!pendingData) return false

    const withinTrialPeriodData = pendingData.contents.filter(
      (content: any) => content.reason.type === 'cancellationWithinTrialPeriod'
    )

    return withinTrialPeriodData.length > 0
  }

  const cancellationStartedDate = (pendingData: any) => {
    if (!pendingData) return null

    const cancellationStartedDate = pendingData?.contents?.filter(
      (content: any) => content.type === 'cancellation'
    )

    const cancellationStartDate =
      cancellationStartedDate?.[0]?.value?.cancellationStarted

    return cancellationStartDate || null
  }

  const cancelledData = getPendingCancellation(pendingData)
  const cancelledInRemorse = isCancelledInRemorse(pendingData)
  const cancellationStartDate = cancellationStartedDate(pendingData)

  return {
    isCancelledInRemorse: cancelledInRemorse,
    cancelledData,
    cancellationStartDate
  }
}

export default checkRemorsePendingTrailData
