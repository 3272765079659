import {
  CardError,
  LoadingHandler
} from '@monetization/hpaip-ui-shared-components'
import IconCalendarDay from '@veneer/core/dist/esm/scripts/icons/icon_calendar_day'
import React from 'react'
import { CardErrorContent } from './EstimatedChargesCard.styles'
import { EstimatedChargesHeightCard } from 'src/components/styles'
import ChargesCard from './shared/ChargesCard'

const PendingCard: React.FunctionComponent<any> = ({
  t,
  isReplacement,
  error,
  estimatedChargeData,
  disableCardLink
}) => {
  const estimatedStates = {
    isCancelState: [
      <>
        <CardErrorContent>
          {t(
            'dashboard.paas.estimated-charges.pending',
            'Your monthly charges amount will be displayed here after you set up the printer'
          )}
        </CardErrorContent>
      </>
    ]
  }
  return isReplacement ? (
    <ChargesCard
      estimatedChargeData={estimatedChargeData}
      t={t}
      error={error}
      disableCardLink={disableCardLink}
    />
  ) : (
    <EstimatedChargesHeightCard>
      <LoadingHandler
        loading={false}
        error={true}
        $position="absolute"
        loadingText={t('dashboard.paas.loading', 'Loading...')}
        customError={
          <CardError
            forceHeight={false}
            icon={<IconCalendarDay size={32} color={'gray9'} />}
            texts={estimatedStates.isCancelState}
          />
        }
      ></LoadingHandler>
    </EstimatedChargesHeightCard>
  )
}
export default PendingCard
