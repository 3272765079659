import { PrinterType } from 'src/utils/constants'
import type {
  InkPlanInfo,
  MultipleSubscriptionPlan
} from '../../types/planInfo'
import { CoreOfferPlanInfo } from '../../types/planInfo'
import type {
  ProductBundleItem,
  ProductResult,
  ProductResultNew
} from '../../types/graphql'
import moment from 'moment'
import { LEASE_PRICE_TYPE, LEASE_PRODUCT_TYPE } from 'src/helpers/constanst'

const findOptionsInkPlan = (
  optionResults: any,
  sku: string
): InkPlanInfo | null => {
  const filteredArray = optionResults.filter(
    (edit) => edit.product.value.productSku === sku
  )
  const inkPlan = filteredArray.length > 0 ? filteredArray[0] : null

  if (!inkPlan) return null
  return {
    sku: inkPlan.product?.value?.productSku,
    pages: inkPlan.product?.value?.pagesPerMonth ?? 0,
    price: inkPlan.product?.value?.price?.cost ?? 0,
    rolloverPages: inkPlan.product?.value?.maxRolloverPages ?? 0,
    additionalPages: inkPlan.product?.value?.overageBlockSize ?? 0,
    additionalPagesPrice: inkPlan.product?.value?.overageBlockPrice ?? 0,
    leasePrice:
      inkPlan?.outcomes.length > 0
        ? inkPlan?.outcomes?.filter(
            (outcome) =>
              outcome.type === LEASE_PRICE_TYPE &&
              outcome.value?.productType === LEASE_PRODUCT_TYPE
          )?.[0]?.value?.price?.cost || 0
        : 0
  }
}

const findCoreOfferPlan = (
  results: ProductResultNew,
  sku: string
): CoreOfferPlanInfo | null => {
  const printerBundle = results?.[0]
  const coreOfferBundle: ProductBundleItem = printerBundle?.items.find(
    (item) => item.title == 'Core Offer'
  )

  const coreOffer = coreOfferBundle?.options?.find(
    (plan) => plan.product.sku === sku
  )

  if (!coreOffer) return null
  return {
    sku: coreOffer.product?.sku,
    title: coreOffer.product?.hp_short_config_name,
    productimage: coreOffer.product?.thumbnail.url
  }
}

const findCoreOfferPlanMultipleSubscription = (
  results: ProductResult,
  sku: string,
  subscriptionId: string,
  index: number
): MultipleSubscriptionPlan | null => {
  const printerBundle = results?.products?.items[0]
  const coreOfferBundle: ProductBundleItem = printerBundle?.items.find(
    (item) => item.title == 'Core Offer'
  )

  const coreOffer = coreOfferBundle?.options?.find(
    (plan) => plan.product.sku === sku
  )

  if (!coreOffer) return null
  return {
    id: index,
    label: coreOffer.product?.hp_short_config_name,
    planId: subscriptionId
  }
}

const calculateContractDuration = (data: ProductResultNew) => {
  const printerBundle = data?.[0]
  const inkBundle = {
    options: printerBundle?.items?.reduce((accumulator, item) => {
      const filtered = item?.options?.filter(
        (option) => option?.product?.hp_product_type_label === 'leaseReceivable'
      )
      return accumulator.concat(filtered)
    }, [])
  }
  const months = inkBundle?.options?.[0]?.product?.hp_contract_duration ?? 0
  const duration = moment.duration(months, 'months')
  const upgradeEligibilty = duration.years()
  return upgradeEligibilty
}

const findIsCissPrinter = async (results: ProductResult) => {
  const productsBundle = results[0]
  const itemBundle = {
    options: productsBundle?.items?.reduce((accumulator, item) => {
      const filtered = item?.options?.filter(
        (option) =>
          option?.product?.hp_supply_type_label === PrinterType.CISS_PRINTER
      )
      return accumulator.concat(filtered)
    }, [])
  }
  const isCissPrinter = itemBundle?.options?.length > 0 ? true : false
  return isCissPrinter
}

export {
  findCoreOfferPlan,
  findCoreOfferPlanMultipleSubscription,
  findOptionsInkPlan,
  calculateContractDuration,
  findIsCissPrinter
}
