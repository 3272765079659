import { useState, useEffect, useCallback } from 'react'
import { findCoreOfferPlanMultipleSubscription } from '../../src/api/services'

export interface PrinterData {
  id: string
  label: string
  planId: string
}

interface UseFetchMultipleSkusResult {
  productData: any[]
  loading: boolean
  error: any
}

const useFetchMultipleSubscription = (
  multipleSubscriptions: any,
  printerProps: any
): UseFetchMultipleSkusResult => {
  const [productData, setProductData] = useState<any[]>([])
  const [error, setError] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)

  const processSkus = useCallback(
    async (
      parentSkus: string[],
      productSkus: string[],
      multipleSubId: string[]
    ) => {
      setLoading(true)

      try {
        const newProductData = parentSkus.map((item, index) => {
          const productInfo = printerProps[item] // Fetch product from props instead of API
          if (!productInfo) {
            throw new Error(`Product not found for SKU: ${item}`)
          }

          return findCoreOfferPlanMultipleSubscription(
            productInfo,
            productSkus[index],
            multipleSubId[index],
            index
          )
        })

        setProductData(newProductData)
      } catch (error) {
        console.error('Error processing SKUs:', error)
        setError(true)
      } finally {
        setLoading(false)
      }
    },
    [printerProps]
  )

  useEffect(() => {
    const fetchMultipleSku = async () => {
      const { productSkus, parentSkus, multipleSubId } = getProductParentSku(
        multipleSubscriptions
      )
      await processSkus(parentSkus, productSkus, multipleSubId)
    }

    fetchMultipleSku()
  }, [multipleSubscriptions, processSkus])

  const getProductParentSku = (subscriptionData) => {
    const productSkus: string[] = []
    const parentSkus: string[] = []
    const multipleSubId: string[] = []

    subscriptionData?.forEach((subscription: any) => {
      subscription?.entities?.forEach((entity: any) => {
        if (entity?.entityType === 'iot-printer') {
          productSkus.push(entity.product.value.productSku)
          parentSkus.push(entity.product.value.parentProductSku)
          multipleSubId.push(subscription.subscriptionId)
        }
      })
    })

    return { productSkus, parentSkus, multipleSubId }
  }

  return { productData, loading, error }
}

export default useFetchMultipleSubscription
