import { ReturnData } from 'src/types/return'

const doesNotContainItemReceived = (part: any): boolean => {
  return !part.history.some((entry) => entry.status === 'itemReceived')
}

export const getDeviceReturnDetails = (
  fulfillmentOrderId: string,
  serialNumber: string,
  returnsList: any
): ReturnData => {
  try {
    const returnsArray = Array.isArray(returnsList)
      ? returnsList
      : [returnsList]

    const filteredReturnList = returnsArray?.filter(
      (item: any) => item.fulfillmentOrderId === fulfillmentOrderId
    )

    let status
    let returnTimeStamp
    let isFedxDisplay

    if (filteredReturnList.length > 0) {
      const returnStatus = filteredReturnList[0].parts.filter(
        (item: any) => item.serialNumber === serialNumber
      )

      if (returnStatus.length > 0) {
        status = returnStatus[0].status
        returnTimeStamp = returnStatus[0].timeStamp
        isFedxDisplay = doesNotContainItemReceived(returnStatus[0])
      }
    }

    return {
      status: status,
      returnNumber: filteredReturnList[0].returnNumber,
      isFedxDisplay: isFedxDisplay,
      timeStamp: filteredReturnList[0].createdAt,
      timeoutInDays: filteredReturnList[0].timeoutInDays,
      returnTimeStamp: returnTimeStamp
    }
  } catch (error) {
    console.error('Error calculating device return details:', error)

    return {
      status: null,
      returnNumber: null,
      isFedxDisplay: false,
      timeStamp: null,
      timeoutInDays: 0,
      returnTimeStamp: null
    }
  }
}
