import React, { FC, useEffect, useMemo, useState } from 'react'
import {
  PaperDetails,
  AddOnPaperStepOne,
  Row,
  PaperOnPaasModal
} from '@monetization/hpaip-ui-shared-components'
import {
  SubscriptionStateEnum,
  useFlags
} from '@monetization/hpaip-notification-rules-react'
import useUpdateSubscription from 'src/hooks/useUpdateSubscription'
import Modal from '@veneer/core/dist/esm/scripts/modal'
import Button from '@veneer/core/dist/esm/scripts/button'
import { ErrorButtonsContainer } from './style'
import { calculateContractDuration } from '../../api/services'
import redirectUrl from '../../utils/redirectUrl'

interface PaperWrapperProps {
  printerProps?: any
  printerData?: any
  handleAddedPaperNotification?: (value: boolean) => void
  commonProps?: any
  inkPlan?: any
  triggerPaperModal?: any
  shippingAddress?: any
  paperEligibilityInfo?: any
  printerName?: any
  resetModalPaper?: any
}
const PaperWrapper: FC<PaperWrapperProps> = (props: PaperWrapperProps) => {
  const flags = useFlags()
  const {
    printerProps,
    printerData,
    handleAddedPaperNotification,
    commonProps,
    inkPlan,
    triggerPaperModal,
    shippingAddress,
    paperEligibilityInfo,
    printerName,
    resetModalPaper
  } = props

  const isChangeShippingEnabled =
    printerData?.printer?.state === SubscriptionStateEnum.SUBSCRIBED
  const [modalState, setModalState] = useState({
    stepOne: false,
    stepTwo: false,
    error: false
  })
  const [btnClicked, setBtnClicked] = useState(false)
  const { t } = commonProps
  const [duration, setDuration] = useState(0)

  useEffect(() => {
    if (triggerPaperModal) {
      setModalState({ ...modalState, stepOne: true })
    }
  }, [triggerPaperModal])

  const { enabled, priceInfo, optionId } = paperEligibilityInfo

  useEffect(() => {
    if (
      printerProps?.graphQLproductData &&
      printerProps.graphQLproductData.length > 0
    ) {
      const durations = calculateContractDuration(
        printerProps?.graphQLproductData
      )
      setDuration(durations)
    }
  }, [printerProps?.graphQLproductData])

  const optimizedValues = useMemo(() => {
    const amount = isNaN(inkPlan?.price + inkPlan?.leasePrice)
      ? 0
      : inkPlan?.price + inkPlan?.leasePrice

    const currentPlanPrice = Math.abs(amount).toFixed(2)
    const totalPlanPrice =
      (parseFloat(currentPlanPrice) + priceInfo).toFixed(2) || 0

    const currentPagesPerMonth = inkPlan?.pages ?? 0

    return { totalPlanPrice, currentPagesPerMonth }
  }, [inkPlan?.price, inkPlan?.leasePrice, inkPlan?.pages])

  const { totalPlanPrice, currentPagesPerMonth } = optimizedValues

  const subscriptionId: string = printerData?.root?.subscriptionId
  const { updateSubscription } = useUpdateSubscription(
    subscriptionId,
    optionId,
    commonProps
  )

  const handlePaperStepOneSubmit = async (isResubmit = false) => {
    if (isResubmit) {
      setBtnClicked(true)
    }

    const success = await updateSubscription()

    if (isResubmit && !success) {
      setBtnClicked(false)
    }

    resetModalPaper(false)
    setModalState({ stepOne: false, stepTwo: success, error: !success })
  }

  const handleChange = () =>
    redirectUrl('/account-details/shipping-billing', commonProps?.navigation)

  return (
    <Row>
      {enabled && (
        <>
          <PaperDetails
            pricePlan={String(priceInfo)}
            pagesPerMonth={String(currentPagesPerMonth)}
            handleButtonClick={() =>
              setModalState({ ...modalState, stepOne: true })
            }
          />
          <AddOnPaperStepOne
            show={modalState.stepOne}
            pricePlan={String(priceInfo)}
            pagesPerMonth={String(currentPagesPerMonth)}
            onClose={() => {
              resetModalPaper(false)
              setModalState({ ...modalState, stepOne: false })
            }}
            onSubmit={handlePaperStepOneSubmit}
            flags={flags}
          />
          <PaperOnPaasModal
            onClose={() => {
              handleAddedPaperNotification(true)
              setModalState({ ...modalState, stepTwo: false })
            }}
            show={modalState.stepTwo}
            pricePlan={String(totalPlanPrice)}
            pagesPerMonth={String(currentPagesPerMonth)}
            shippingAddress={{
              fullName: `${shippingAddress?.firstName} ${shippingAddress?.lastName}`,
              address: `${shippingAddress?.address}`,
              address2: `${shippingAddress?.address2}`,
              city: `${shippingAddress?.city}`,
              state: `${shippingAddress?.state}`,
              postalCode: `${shippingAddress?.postalCode}`,
              countryCode: `${shippingAddress?.countryCode}`
            }}
            printerName={printerName}
            handleChange={handleChange}
            duration={duration}
            changeShippingAddressEnabled={isChangeShippingEnabled}
          />
          <Modal
            align="start"
            closeButton={true}
            show={modalState.error}
            onClose={() => setModalState({ ...modalState, error: false })}
            data-testid="modal-error"
            footer={
              <ErrorButtonsContainer>
                <Button
                  appearance="secondary"
                  loading={btnClicked}
                  onClick={() => handlePaperStepOneSubmit(true)}
                >
                  {t(
                    'dashboard.paper-wrapper.error-section.button',
                    'Try it again'
                  )}
                </Button>
              </ErrorButtonsContainer>
            }
            title={t(
              'dashboard.paper-wrapper.error-section.title',
              'Something went wrong'
            )}
          >
            <p>
              {t(
                'dashboard.paper-wrapper.error-section.description',
                'It has not been possible to add the Paper Add-on service to your plan'
              )}
            </p>
          </Modal>
        </>
      )}
    </Row>
  )
}

export const MemoizedPaperWrapper = React.memo(
  PaperWrapper,
  (prevProps, nextProps) =>
    prevProps.printerProps === nextProps.printerProps &&
    prevProps.printerData === nextProps.printerData &&
    prevProps.commonProps === nextProps.commonProps &&
    prevProps.triggerPaperModal === nextProps.triggerPaperModal &&
    prevProps.printerName === nextProps.printerName
)
