import React from 'react'
import Images from '../../../assets/images/index'
import {
  FedexDeviceDetails,
  DeviceSerialNumber,
  Title,
  DeviceImage,
  DeviceImageWrapper,
  DeviceContentWrapper
} from './styles'
import { CommonProps } from 'src/utils/common-util'

interface DeviceComponentProps {
  commonProps: CommonProps
  corePlan: any
  printerData: any
}

const DeviceComponent: React.FC<DeviceComponentProps> = ({
  commonProps,
  corePlan,
  printerData
}) => {
  const { t } = commonProps
  return (
    <FedexDeviceDetails data-testid="deviceVisible">
      <DeviceImageWrapper>
        <DeviceImage
          alt={`${corePlan?.title} ${printerData?.printer?.entityDetails?.serialNumber}`}
          src={corePlan?.productimage}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null
            currentTarget.src = Images.printerPlaceholder
          }}
        />
      </DeviceImageWrapper>
      <DeviceContentWrapper>
        <Title>{corePlan?.title}</Title>
        <DeviceSerialNumber>
          {t('dashboard.FedExCard.SerialNumber', 'Serial number: ')}
          {''}
          {printerData?.printer?.entityDetails?.serialNumber}
        </DeviceSerialNumber>
      </DeviceContentWrapper>
    </FedexDeviceDetails>
  )
}

export { DeviceComponent }
