import {
  CardError,
  InkPlanDetails,
  LoadingHandler
} from '@monetization/hpaip-ui-shared-components'
import {
  navigationLinksHpAllIn,
  SubscriptionStateEnum
} from '@monetization/hpaip-notification-rules-react'
import IconPrinterInkDrop from '@veneer/core/dist/esm/scripts/icons/icon_printer_ink_drop'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { findOptionsInkPlan } from '../../api/services'
import { CardLink, ChevronRightIcon, CardLinkWrapper } from '../styles'

import { StyledIconPrinterInkDrop } from './InkPlanDetailsCard.styles'
import { PrintPlanStaticHeightCard } from './styles'
import { isDeliquency } from '../../utils/common-util'
import AnalysticsObserver from '../Analytics/AnalysticsObserver'
import { EventNames, WrapperType } from '../Analytics/AnaysticsConstants'

const InkPlanDetailsCard: React.FunctionComponent<any> = ({
  printerData,
  printerOptionDetails,
  deliquencyNotification,
  isSubscriptionHavePaper,
  commonProps,
  inkPlan,
  setInkPlan,
  resetModalPaper,
  isAddPaperEnabled,
  isPaperAdded
}) => {
  const { t, navigation } = commonProps
  const isActive = isDeliquency(deliquencyNotification)
  const [error, setError] = useState<boolean>(false)
  const [hasChecked, setHasChecked] = useState(false)
  const [addRemovePaper, setaddRemovePaper] = useState<string>()

  const { disableCard, disableCardLink } = useMemo(() => {
    const disableCardState = [SubscriptionStateEnum.CANCELED]
    const disableCardLinks = [
      SubscriptionStateEnum.CANCELED,
      SubscriptionStateEnum.PENDING,
      SubscriptionStateEnum.SUSPENDED
    ]

    if (printerData?.isLoading || !printerData?.printer)
      return { disableCard: true, disableCardLink: true }
    return {
      disableCard:
        disableCardState.includes(printerData?.printer.state) ||
        printerData?.isCancellationTriggered,
      disableCardLink: disableCardLinks.includes(printerData?.printer.state)
    }
  }, [
    printerData?.isLoading,
    printerData?.printer,
    printerData?.isCancellationTriggered
  ])

  useEffect(() => {
    const getPlanDetails = async () => {
      if (printerData?.isLoading || !printerData?.instantInk) return
      const values = printerData?.instantInk?.product?.value
      const { productSku } = values || {}
      const optionDetails = printerOptionDetails?.entities[0]?.edit
      setInkPlan(findOptionsInkPlan(optionDetails, productSku))
    }
    if (printerOptionDetails && !printerOptionDetails.status) {
      setError(false)
      getPlanDetails()
    } else if (hasChecked) {
      setError(true)
    } else {
      setHasChecked(true)
    }
  }, [printerData, printerOptionDetails])

  const computeLoadingStatus = () => {
    if (inkPlan) {
      return printerData?.isLoading
    } else {
      return printerData?.isLoading || error
    }
  }

  const computeErrorStatus = () => {
    return printerData?.error || (!inkPlan && !computeLoadingStatus()) || error
  }
  const isLoading = computeLoadingStatus()
  const isError = computeErrorStatus()

  const shouldShowFooter = !isLoading && !isError

  /* istanbul ignore next */
  const handleaddpaperService = useCallback(() => {
    resetModalPaper(true)
  }, [resetModalPaper])

  /* istanbul ignore next */
  const handleremovepaperService = async () => {
    const path = `/ucde/hp-all-in-print-plan/cancellation/${printerData?.root?.subscriptionId}?entity=paper`
    const hasUcdeInPath = window.location.pathname.includes('/ucde')
    const finalPath = hasUcdeInPath ? path : path.replace('/ucde/', '')
    navigation.push(finalPath)
  }
  useEffect(() => {
    async function fetchData() {
      if (printerData?.isRemovePaper || isPaperAdded) {
        setaddRemovePaper('removePaperService')
      } else if (isAddPaperEnabled) {
        setaddRemovePaper('addPaperService')
      }
    }
    if (printerData) fetchData()
  }, [printerData, isPaperAdded])

  return (
    <PrintPlanStaticHeightCard
      isSubscriptionHavePaper={isSubscriptionHavePaper}
      icon={<IconPrinterInkDrop color={'gray12'} size={32} />}
      title={t('dashboard.paas.ink-plan-details.title', 'My plan')}
      contentPosition="bottom"
      autoHeight="true"
      $disabled={isLoading ? false : (disableCard && !isError) || isActive}
      footer={
        shouldShowFooter && (
          <AnalysticsObserver
            wrapper={WrapperType.EventWrapper}
            eventName={EventNames.ClickChangePlan}
            printerData={printerData}
          >
            <CardLink
              path={navigationLinksHpAllIn.plans}
              disabled={disableCardLink}
              isDisabled={isActive}
              text={
                <CardLinkWrapper id="change-plan-button">
                  {t(
                    'dashboard.paas.ink-plan-details.change-plan',
                    'Change plan'
                  )}

                  <ChevronRightIcon disabled={disableCardLink} />
                </CardLinkWrapper>
              }
              commonProps={commonProps}
            />
          </AnalysticsObserver>
        )
      }
    >
      <LoadingHandler
        loading={isLoading}
        error={Boolean(isError)}
        $position="absolute"
        loadingText={t('dashboard.paas.loading', 'Loading...')}
        customError={
          <AnalysticsObserver
            wrapper={WrapperType.DisplayObserver}
            eventName={EventNames.DisplayedLoadingDataIssueNotification}
            printerData={printerData}
          >
            <CardError
              forceHeight={false}
              icon={<StyledIconPrinterInkDrop size={32} />}
              texts={[
                <>
                  <p>
                    {t(
                      'dashboard.paas.ink-plan-details.error.title.paragraph-1',
                      'Something went wrong.'
                    )}
                  </p>
                  <p>
                    {t(
                      'dashboard.paas.ink-plan-details.error.title.paragraph-2',
                      'Your print plan information did not load.'
                    )}
                  </p>
                </>,

                t(
                  'dashboard.paas.ink-plan-details.error.description',
                  'Please refresh the page.'
                )
              ]}
            />
          </AnalysticsObserver>
        }
      >
        <InkPlanDetails
          t={t}
          pages={inkPlan?.pages}
          rollover={inkPlan?.rolloverPages}
          additional={inkPlan?.additionalPages}
          additionalPrice={inkPlan?.additionalPagesPrice}
          isSubscriptionHavePaper={isSubscriptionHavePaper || isPaperAdded}
          paperEligibility={addRemovePaper}
          handleaddpaperService={handleaddpaperService}
          handleremovePaperService={handleremovepaperService}
        />
      </LoadingHandler>
    </PrintPlanStaticHeightCard>
  )
}

export default InkPlanDetailsCard
