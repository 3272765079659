import {
  CardError,
  DeviceDetail,
  DeviceImage,
  LoadingHandler
} from '@monetization/hpaip-ui-shared-components'
import { SubscriptionStateEnum } from '@monetization/hpaip-notification-rules-react'
import IconPrinter from '@veneer/core/dist/esm/scripts/icons/icon_printer'
import React, { useEffect, useMemo, useState } from 'react'

import { StyledCardContainer } from '../styles'
import { StyledCard, StyledIconPrinter, DeviceImageWrapper } from './styles'
import {
  calculateContractDuration,
  findCoreOfferPlan
} from '../../api/services'
import { CoreOfferPlanInfo } from '../../types/planInfo'
import { isDeliquency } from '../../utils/common-util'
import moment from 'moment'
import AnalysticsObserver from '../Analytics/AnalysticsObserver'
import { EventNames, WrapperType } from '../Analytics/AnaysticsConstants'

const DeviceDetailsCard: React.FunctionComponent<any> = ({
  printerProps,
  printerData,
  deliquencyNotification,
  onPrinterDataReceived,
  commonProps
}) => {
  const { t } = commonProps

  const [corePlan, setCorePlan] = useState<CoreOfferPlanInfo | null>()
  const isActive = isDeliquency(deliquencyNotification)

  const duration = useMemo(() => {
    return calculateContractDuration(printerProps?.graphQLproductData)
  }, [printerProps?.graphQLproductData])

  const { disableCard, isPreArrival } = useMemo(() => {
    const disableCardState = [
      SubscriptionStateEnum.CANCELED,
      SubscriptionStateEnum.CANCELING
    ]
    if (!printerData?.printer)
      return { disableCard: true, disableCardLink: true }
    return {
      disableCard: disableCardState.includes(printerData?.printer.state),
      disableCardLink: disableCardState.includes(printerData?.printer.state),

      isPreArrival:
        printerData?.printer.state === SubscriptionStateEnum.PENDING ||
        !printerData?.printer?.entityDetails?.uniqueDeviceId
    }
  }, [printerData?.printer])

  const productSerialNumber = printerData?.printer?.entityDetails?.serialNumber

  const values = printerData?.printer?.product?.value
  const { productSku } = values || {}
  useEffect(() => {
    if (
      printerProps?.graphQLproductData &&
      printerProps.graphQLproductData.length > 0
    ) {
      setCorePlan(
        findCoreOfferPlan(printerProps.graphQLproductData, productSku)
      )
    }
  }, [printerProps?.graphQLproductData])

  useEffect(() => {
    onPrinterDataReceived(corePlan)
  }, [corePlan, onPrinterDataReceived])

  const isLoading = !isPreArrival && !printerData?.printer

  const emptyData = !isPreArrival && !isLoading

  const isError = Boolean(
    isPreArrival ||
      (isLoading && emptyData) ||
      !(
        printerProps?.graphQLproductData &&
        printerProps.graphQLproductData.length > 0
      )
  )

  return (
    <StyledCard
      $disabled={
        isLoading
          ? false
          : (disableCard && !isError) ||
            isActive ||
            printerData?.isCancellationTriggered
      }
    >
      <StyledCardContainer
        $isLoading={isLoading}
        full="true"
        icon={<IconPrinter size={32} color={'gray9'} />}
        title={corePlan?.title ? corePlan?.title : ''}
        aside={
          !isError &&
          !isLoading && (
            <DeviceImageWrapper>
              <DeviceImage
                deviceImage={corePlan?.productimage}
                modelName={corePlan?.title}
                serialNumber={productSerialNumber}
                type="iot-printer"
              />
            </DeviceImageWrapper>
          )
        }
      >
        <LoadingHandler
          loading={isLoading}
          error={isError}
          $position="absolute"
          loadingText={t('dashboard.paas.loading', 'Loading...')}
          customError={
            <AnalysticsObserver
              wrapper={WrapperType.DisplayObserver}
              eventName={EventNames.DisplayedLoadingDataIssueNotification}
              printerData={printerData}
            >
              <CardError
                disabled={disableCard}
                icon={<StyledIconPrinter size={72} />}
                texts={
                  isPreArrival
                    ? [
                        t(
                          'dashboard.cards.device-details.pre-arrival',
                          'Your device details will be displayed here after you set up the printer'
                        )
                      ]
                    : [
                        <>
                          <p>
                            {t(
                              'dashboard.cards.device-details.title.paragraph-1',
                              'Something went wrong.'
                            )}
                          </p>
                          <p>
                            {t(
                              'dashboard.cards.device-details.title.paragraph-2',
                              "We couldn't load your plan details."
                            )}
                          </p>
                        </>,
                        t(
                          'dashboard.cards.device-details.error.description',
                          'Please refresh the page.'
                        )
                      ]
                }
              />
            </AnalysticsObserver>
          }
        >
          <DeviceDetail
            t={t}
            //modelName={corePlan?.title ? corePlan?.title : ''}
            productNumber={corePlan?.sku ? corePlan?.sku.split('#')[0] : ''}
            serialNumber={productSerialNumber}
            eligiblePrinterRefreshDate={moment(
              printerData.printer?.entityStartDate.split('T')[0]
            )
              .add(duration, 'y')
              .format('MMM DD, YYYY')}
          />
        </LoadingHandler>
      </StyledCardContainer>
    </StyledCard>
  )
}

export default DeviceDetailsCard
