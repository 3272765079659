import React, { useEffect, useState } from 'react'
import {
  useCommonNotification,
  useFlags,
  Subscription
} from '@monetization/hpaip-notification-rules-react'
import { Page } from '../Page'
import { CommonProps } from 'src/utils/common-util'
import { Analytics } from '../Analytics'
import {
  HpxHeader,
  LoadingHandler
} from '@monetization/hpaip-ui-shared-components'
import { ErrorSection } from '../ErrorComponent/ErrorSection'
import { HeaderSection } from '../Page/styles'

interface Props {
  subscriptionId: string
  subscriptionsData: Subscription
  commonProps: CommonProps
  refreshPrinterSelection: any
  setIndex: React.Dispatch<React.SetStateAction<number>>
  index: number
}

const InitialComponent: React.FunctionComponent<Props> = ({
  subscriptionId,
  subscriptionsData,
  commonProps,
  refreshPrinterSelection,
  setIndex,
  index
}) => {
  const { t } = commonProps
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<boolean>(false)
  const flags = useFlags()
  const flagObject = {
    enableBuyerRemorseCancellation: flags?.enableBuyerRemorseCancellation,
    enableAddPaperPostEnrollment: flags?.enableAddPaperPostEnrollment,
    pfEnableNewReplacementFlow: flags?.pfEnableNewReplacementFlow
  }

  const data = useCommonNotification(
    'overview',
    subscriptionId,
    flagObject,
    null,
    null
  )

  useEffect(() => {
    if (data?.printerData?.root != null) {
      setLoading(false)
      setError(false)
    } else if (data?.printerData?.error) {
      setLoading(false)
      setError(true)
    }
  }, [data?.printerData?.error, data?.printerData?.root])

  return (
    <>
      <HeaderSection>
        <HpxHeader title={t('dashboard.header-title', 'Plan overview')} />
      </HeaderSection>

      {loading && <LoadingHandler loading={loading} error={false} />}
      {!loading && error && (
        <LoadingHandler
          loading={loading}
          error={error}
          customError={
            <ErrorSection
              t={t}
              title="Something went wrong"
              description="Your account information did not load."
              buttonText="Try again"
            />
          }
        />
      )}
      {!loading && !error && (
        <>
          <Analytics analytics={commonProps?.analytics} printerProps={data}>
            <Page
              setIndex={setIndex}
              index={index}
              printerProps={data}
              subscriptionsData={subscriptionsData}
              commonProps={commonProps}
              refreshPrinterSelection={refreshPrinterSelection}
            />
          </Analytics>
        </>
      )}
    </>
  )
}

export default InitialComponent
