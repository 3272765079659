import { useEffect, useState } from 'react'
import { getDeviceReturnDetails } from 'src/utils/getDeviceReturnDetails'
import { ReturnData } from 'src/types/return'

export const useDeviceReturnDetails = (
  fulfillmentOrderId: string,
  serialNumber: string,
  returnData: any
): ReturnData | null => {
  const [deviceReturnStatus, setDeviceReturnStatus] =
    useState<ReturnData | null>(null)

  useEffect(() => {
    if (returnData) {
      const deviceReturnDetails = getDeviceReturnDetails(
        fulfillmentOrderId,
        serialNumber,
        returnData
      )
      setDeviceReturnStatus(deviceReturnDetails)
    }
  }, [fulfillmentOrderId, serialNumber, returnData])

  return deviceReturnStatus
}
