import React, { useState, useEffect } from 'react'
import {
  FedexCard,
  Container,
  Title,
  Description,
  Link,
  ContentBlock,
  ContentCard,
  FooterContent
} from './styles'
import IconInfo from '@veneer/core/dist/esm/scripts/icons/icon_info'

import { QrCardComponent } from './QrCardComponent/QrCardComponent'
import { DeviceComponent } from './DeviceComponent/DeviceComponent'
import { CommonProps } from 'src/utils/common-util'
import { ReturnData } from 'src/types/return'
import { findIsCissPrinter } from '../../api/services'
interface FedxReturnProps {
  commonProps: CommonProps
  printerProps: any
  corePlan: any
  printerData: any
  returnDetails: ReturnData
}

const FedexReturnCard: React.FC<FedxReturnProps> = ({
  commonProps,
  printerProps,
  corePlan,
  printerData,
  returnDetails
}) => {
  const [isCissPrinter, setIsCissPrinter] = useState<boolean>(false)
  const shouldRender =
    printerData?.printer?.state !== 'inactive' && // check printer state should not ne inactive
    returnDetails?.status && // check for return api fail or success
    corePlan && // check for grpahQl api fail or success
    returnDetails?.isFedxDisplay && // check for eligible for fedX
    returnDetails?.returnNumber

  const { t } = commonProps

  const hasGraphQLData = !!(
    printerProps?.graphQLproductData &&
    printerProps.graphQLproductData.length > 0
  )

  useEffect(() => {
    const fetchCissPrinterFlag = async () => {
      if (hasGraphQLData) {
        try {
          const cissPrinterFlag = await findIsCissPrinter(
            printerProps?.graphQLproductData
          )
          setIsCissPrinter(cissPrinterFlag)
          printerData.printer.isCissPrinter = cissPrinterFlag
        } catch (error) {
          console.error('Error fetching CISS Printer Flag:', error)
        }
      }
    }

    fetchCissPrinterFlag() // Call the async function inside useEffect
  }, [printerProps?.graphQLproductData])

  return shouldRender ? (
    <FedexCard data-testid="fedxVisible">
      <Container>
        <IconInfo filled={true} size={24} color="hpBlue7" />
        <ContentCard>
          <Title>
            {' '}
            {t('dashboard.FedExCard.title', 'How to return the printer')}
          </Title>
          <Description>
            {t(
              'dashboard.FedExCard.description',
              'Show this QR code in a participating FedEx Office, FedEx Ship Center, or Walgreens location, where a team member will print your label. FedEx has sent you an email with the QR code. (If you don’t see it, please check your spam folder.)'
            )}
          </Description>

          <Link
            rel="noreferrer"
            target="_blank"
            href="https://local.fedex.com/en/easy-returns"
          >
            {t(
              'dashboard.FedExCard.link2',
              'Find a FedEx easy return location'
            )}
          </Link>

          <ContentBlock>
            <QrCardComponent
              commonProps={commonProps}
              returnDetails={returnDetails}
              isCissPrinter={isCissPrinter}
            />
            <DeviceComponent
              commonProps={commonProps}
              corePlan={corePlan}
              printerData={printerData}
            />
          </ContentBlock>
          <FooterContent>
            {t(
              'dashboard.FedExCard.content',
              'You will receive an email confirming we received your printer.'
            )}
          </FooterContent>
        </ContentCard>
      </Container>
    </FedexCard>
  ) : null
}

export { FedexReturnCard }
